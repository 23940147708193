import axios, { InternalAxiosRequestConfig } from "axios";
import { blastApi } from "../blastApi";
import { getConfig } from "@portal-frontend-ssr/config";
import { authInterceptor } from "../interceptors/authInterceptor";
import { debugLoggingInterceptor } from "../interceptors/debugLoggingInterceptor";

const blastDeadlockApi = axios.create({
  baseURL: getConfig().API_BASE_URL,
  headers: {
    "server-token": getConfig().DEADLOCK_SERVER_TOKEN,
  },
});

blastApi.interceptors.response.use((response) => response, debugLoggingInterceptor);

export { blastDeadlockApi };
