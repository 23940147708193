import { z } from "zod";
import { BaseStatsSchema } from "../shared/schemas";

export const RlPlayerSchema = z.object({
  id: z.string(),
  platform: z.string(),
  playerName: z.string(),
  playerDisplayName: z.string(),
  lastSeenMatch: z.string(),
  nameHistory: z.array(z.string()),
});

export const RlPlayerStatsSchema = BaseStatsSchema.extend({
  playerId: z.string(),
  playerName: z.string(),
  teamName: z.array(z.string()),
});

export type RlPlayerStats = z.input<typeof RlPlayerStatsSchema>;

export const RlPlayerInfoSchema = z.object({
  playerId: z.string(),
  playerName: z.string(),
  playerPlatform: z.string(),
});
export type RlPlayerInfo = z.infer<typeof RlPlayerInfoSchema>;
