import { z } from "zod";
import { GameIdSchema } from "../../shared/schemas/GameId";

const TournamentMetadataTicketingSchema = z
  .object({
    items: z
      .object({
        title: z.string(),
        startDate: z.string(),
        endDate: z.string(),
        cta: z.object({
          url: z.string(),
        }),
      })
      .array(),
  })
  .nullish()
  .catch((context) => {
    console.error(context);
    return null;
  });

const TournamentMetadataSponsorsSchema = z
  .object({
    header: z.string().nullish(),
    items: z
      .object({
        logoKey: z.string(),
        url: z.string(),
      })
      .array(),
  })
  .nullish()
  .catch((context) => {
    console.error(context);
    return null;
  });

const TournamentMetadataHeaderCtaSchema = z
  .object({ text: z.string(), url: z.string() })
  .nullish()
  .catch((context) => {
    console.error(context);
    return null;
  });

export const DbTournamentMetaDataSchema = z.object({
  sponsors: TournamentMetadataSponsorsSchema,
  ticketing: TournamentMetadataTicketingSchema,
  headerCta: TournamentMetadataHeaderCtaSchema,
});
export type DbTournamentMetaData = z.infer<typeof DbTournamentMetaDataSchema>;

export const BaseTournamentSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable().optional(),
  circuit: z.object({
    id: z.string(),
    name: z.string(),
    gameId: GameIdSchema,
  }),
  prizePool: z.string().nullish(),
  numberOfTeams: z.number().nullish(),
  location: z.string().nullish(),
  externalId: z.string().nullish(),
  region: z.string().nullish(),
  grouping: z.string().nullish(),
  description: z.string().nullable(),
  metadata: DbTournamentMetaDataSchema.nullable(),
});
export type BaseTournament = z.infer<typeof BaseTournamentSchema>;

export const TournamentSchema = BaseTournamentSchema.extend({
  children: z.array(BaseTournamentSchema).nullable().optional(),
  parent: BaseTournamentSchema.nullable().optional(),
});

export type Tournament = z.infer<typeof TournamentSchema>;
