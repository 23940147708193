import { z } from "zod";

export const StageSchema = z.object({
  id: z.string(),
  name: z.string(),
  format: z.string(),
  numberOfTeams: z.string().nullable(),
  metadata: z.any().nullable(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  index: z.number(),
});
