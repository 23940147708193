import { z } from "zod";

export const HighRankedHeroItemsSchema = z.object({
  heroId: z.string(),
  position: z.string().nullish(),
  facet: z.string().nullish(),
  items: z
    .object({
      coreItems: z
        .object({
          itemsIds: z.array(z.number()),
          matches: z.number(),
          winrate: z.number(),
        })
        .array()
        .nullish(),
      startingItems: z
        .object({
          itemId: z.number(),
          matches: z.number(),
          winrate: z.number(),
        })
        .array()
        .nullish(),
      earlyItems: z
        .object({
          itemId: z.number(),
          matches: z.number(),
          winrate: z.number(),
        })
        .array()
        .nullish(),
    })
    .nullable(),
});

export type HighRankedHeroItems = z.infer<typeof HighRankedHeroItemsSchema>;
