import { z } from "zod";
import { DotaPositionSchema } from "../../shared/Schemas/DotaPositionSchema";

export const HeroTierSchema = z.enum(["S", "A", "B", "C", "D"]);
export type HeroTier = z.infer<typeof HeroTierSchema>;

export const HighRankedHeroesOverallStatsSchema = z.object({
  heroId: z.string(),
  tier: HeroTierSchema.nullish().default("D"),
  facet: z.string().optional(),
  position: DotaPositionSchema.optional(),
  winrate: z.number().nullish(),
  pickrate: z.number().nullish(),
  matches: z.number().nullish(),
  winRank: z.number().nullish(),
  pickRank: z.number().nullish(),
  winrateHistory: z
    .object({
      date: z.string(),
      value: z.number(),
      matches: z.number(),
    })
    .array()
    .optional(),
  pickrateHistory: z
    .object({
      date: z.string(),
      value: z.number(),
      matches: z.number(),
    })
    .array()
    .optional(),
  bestFacet: z.string().nullish(),
  bestPosition: DotaPositionSchema.nullish(),
});

export type HighRankedHeroesOverallStats = z.infer<typeof HighRankedHeroesOverallStatsSchema>;
