import { z } from "zod";

const StatsGroupSchema = z
  .object({
    core: z.object({}).passthrough(),
    boost: z.object({}).passthrough(),
    movement: z.object({}).passthrough(),
    positioning: z.object({}).passthrough(),
    demo: z.object({}).passthrough(),
  })
  .passthrough();

const PlayerMatchStatsSchema = z.object({
  platform: z.string(),
  id: z.string(),
  name: z.string(),
  cumulative: z.object({
    games: z.number(),
    wins: z.number(),
    win_percentage: z.number().optional(),
    play_duration: z.number().optional(),
    ...StatsGroupSchema.shape,
  }),
  game_average: StatsGroupSchema,
});

const RlTeamSchema = z.object({
  name: z.string(),
  players: PlayerMatchStatsSchema.array(),
  score: z.number(),
  didWin: z.boolean(),
  stats: z
    .object({
      players: PlayerMatchStatsSchema.pick({
        name: true,
        id: true,
        platform: true,
      }).array(),
      game_average: StatsGroupSchema,
      cumulative: z.object({
        games: z.number(),
        wins: z.number(),
        win_percentage: z.number().optional(),
        play_duration: z.number().optional(),
        ...StatsGroupSchema.shape,
      }),
    })
    .array(),
});

const GroupsSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const CameraSchema = z.object({
  fov: z.number().optional(),
  height: z.number().optional(),
  pitch: z.number().optional(),
  distance: z.number().optional(),
  stiffness: z.number().optional(),
  swivel_speed: z.number().optional(),
  transition_speed: z.number().optional(),
});

const PlayerIdSchema = z.object({
  platform: z.string().optional(),
  id: z.string().optional(),
});

const PlayerSchema = z
  .object({
    start_time: z.number().optional(),
    end_time: z.number().optional(),
    name: z.string(),
    id: PlayerIdSchema,
    car_id: z.number().optional(),
    car_name: z.string().optional(),
    camera: CameraSchema.optional(),
    steering_sensitivity: z.number().optional(),
    stats: StatsGroupSchema,
  })
  .passthrough();

const MatchTeamSchema = z
  .object({
    color: z.string(),
    name: z.string(),
    goals: z.number(),
    players: z.array(PlayerSchema),
    stats: StatsGroupSchema,
  })
  .passthrough();

const MatchSchema = z.object({
  id: z.string(),
  created: z.string().datetime({ offset: true }),
  status: z.literal("ok"),
  rocket_league_id: z.string(),
  title: z.string(),
  map_code: z.string(),
  match_type: z.string(), // You might want to make this an enum if you have specific types
  team_size: z.number().int().min(1),
  playlist_id: z.string().optional(),
  duration: z.number().int(),
  overtime: z.boolean(),
  overtime_seconds: z.number().int().optional(),
  season: z.number().int(),
  season_type: z.string().optional(), // Could be an enum if you have specific types
  date: z.string().datetime({ offset: true }), // This ensures ISO 8601 datetime format
  date_has_timezone: z.boolean(),
  visibility: z.string(), // Could be an enum with "public" | "private" etc.
  groups: GroupsSchema.array(),
  blue: MatchTeamSchema,
  orange: MatchTeamSchema,
  map_name: z.string(),
});

export const RlSeriesSchema = z.object({
  season: z.string(),
  seriesId: z.string(),
  path: z.string(),
  matchesPlayed: z.number(),
  teamBlue: RlTeamSchema,
  teamOrange: RlTeamSchema,
  matches: MatchSchema.array(),
});
export type RlSeries = z.infer<typeof RlSeriesSchema>;
