import { z } from "zod";
import { HeroTierSchema } from "./HeroTier";

export const HeroRankSchema = z.object({
  date: z.string(),
  heroId: z.coerce.string(),
  heroName: z.string(),
  matchesPlayed: z.number(),
  winRate: z.number(),
  pickRate: z.number(),
  winRank: z.number(),
  trendPercentage: z.number().nullish(),
  tier: HeroTierSchema.nullish().default("S"),
  networth: z.number().nullish(),
  pickRank: z.number().nullish(),
});

export type HeroRank = z.infer<typeof HeroRankSchema>;
