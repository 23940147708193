import { z } from "zod";
import { BaseStatsSchema } from "../shared/schemas";

export const RlTeamStatsSchema = BaseStatsSchema.extend({
  teamName: z.string(),
});

export type RlTeamStats = z.input<typeof RlTeamStatsSchema>;

export const RlTeamSchema = z.object({
  id: z.string(),
  teamDisplayName: z.string(),
  teamNames: z.array(z.string()),
  assignedTeamId: z.string().nullish(),
});

export type RlTeam = z.input<typeof RlTeamSchema>;
