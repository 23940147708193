import { z } from "zod";

export const CS_STRIKLE_MULTIPLAYER_GAME_DIFFICULTY = ["noob", "pro"] as const;
export const CS_STRIKLE_MULTIPLAYER_GAME_DIFFICULTY_SCHEMA = z.enum(CS_STRIKLE_MULTIPLAYER_GAME_DIFFICULTY);

export type CSStrikleMultiplayerGameDifficulty = z.infer<typeof CS_STRIKLE_MULTIPLAYER_GAME_DIFFICULTY_SCHEMA>;

export const CounterStriklePlayerSchema = z.object({
  id: z.string(),
  nickname: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

export type CounterStriklePlayer = z.infer<typeof CounterStriklePlayerSchema>;

export const GameResultSchema = z.object({
  gameId: z.string(),
  players: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      avatarId: z.string(),
    }),
  ),
  winnerId: z.string().optional(),
});

export type GameResult = z.infer<typeof GameResultSchema>;

export const CounterStrikleLeaderboardSchema = z.array(
  z.object({
    playerId: z.string(),
    playerName: z.string(),
    playerAvatarId: z.string(),
    gamesPlayed: z.number(),
    gamesWon: z.number(),
    winRate: z.number(),
    lastGameAt: z.string(),
  }),
);

export type CounterStrikleLeaderboard = z.infer<typeof CounterStrikleLeaderboardSchema>;
