import { z } from "zod";
import { blastDeadlockApi } from "..";

const ResponseSchema = z.object({
  steamId: z.string(),
  accountId: z.string(),
});
export const convertVanityId = async (id: string) => {
  const { data } = await blastDeadlockApi.get(`/v2/tournaments/gamedata/deadlock/users/convert-vanity-id/${id}`);
  return ResponseSchema.parse(data);
};
