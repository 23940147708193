import { z } from "zod";

export const SteamProfileSchema = z.object({
  steamId: z.string(),
  avatar: z.string(),
  personaName: z.string(),
  countryCode: z.string().nullish(),
});

export type SteamProfile = z.infer<typeof SteamProfileSchema>;
