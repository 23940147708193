import { z } from "zod";

// Position schema
export const PositionSchema = z.object({
  x: z.number(),
  y: z.number(),
  z: z.number(),
});

export type Position = z.infer<typeof PositionSchema>;

// Death details schema
export const DeathDetailsSchema = z.object({
  gameTimeS: z.number(),
  killerPlayerSlot: z.number(),
  deathPos: PositionSchema,
  killerPos: PositionSchema,
  deathDurationS: z.number(),
});

export type DeathDetails = z.infer<typeof DeathDetailsSchema>;

// Item schema
export const ItemSchema = z.object({
  gameTimeS: z.number(),
  itemId: z.number(),
  upgradeId: z.number(),
  soldTimeS: z.number(),
  flags: z.number(),
  imbuedAbilityId: z.number(),
});

export type Item = z.infer<typeof ItemSchema>;

// Gold source schema
export const GoldSourceSchema = z.object({
  source: z.number(),
  kills: z.number(),
  damage: z.number(),
  gold: z.number(),
  goldOrbs: z.number(),
});

export type GoldSource = z.infer<typeof GoldSourceSchema>;

// Custom user stat schema
export const CustomUserStatSchema = z.object({
  value: z.number(),
  id: z.number(),
});

export type CustomUserStat = z.infer<typeof CustomUserStatSchema>;
// Player stats schema
export const PlayerStatsSchema = z.object({
  timeStampS: z.number(),
  netWorth: z.number(),
  goldPlayer: z.number(),
  goldPlayerOrbs: z.number(),
  goldLaneCreepOrbs: z.number(),
  goldNeutralCreepOrbs: z.number(),
  goldBoss: z.number(),
  goldBossOrb: z.number(),
  goldTreasure: z.number(),
  goldDenied: z.number(),
  goldDeathLoss: z.number(),
  goldLaneCreep: z.number(),
  goldNeutralCreep: z.number(),
  kills: z.number(),
  deaths: z.number(),
  assists: z.number(),
  creepKills: z.number(),
  neutralKills: z.number(),
  possibleCreeps: z.number(),
  creepDamage: z.number(),
  playerDamage: z.number(),
  neutralDamage: z.number(),
  bossDamage: z.number(),
  denies: z.number(),
  playerHealing: z.number(),
  abilityPoints: z.number(),
  selfHealing: z.number(),
  playerDamageTaken: z.number(),
  maxHealth: z.number(),
  weaponPower: z.number(),
  techPower: z.number(),
  shotsHit: z.number(),
  shotsMissed: z.number(),
  damageAbsorbed: z.number(),
  absorptionProvided: z.number(),
  heroBulletsHit: z.number(),
  heroBulletsHitCrit: z.number(),
  healPrevented: z.number(),
  healLost: z.number(),
  goldSources: z.array(GoldSourceSchema),
  customUserStats: z.array(CustomUserStatSchema),
  damageMitigated: z.number(),
  level: z.number(),
});

export type PlayerStats = z.infer<typeof PlayerStatsSchema>;

// Ping schema
export const PingSchema = z.object({
  pingType: z.number(),
  pingData: z.number(),
  gameTimeS: z.number(),
});

export type Ping = z.infer<typeof PingSchema>;

// Player schema
export const DeadlockPlayerSchema = z.object({
  accountId: z.number(),
  playerSlot: z.number(),
  deathDetails: z.array(DeathDetailsSchema),
  items: z.array(ItemSchema),
  stats: z.array(PlayerStatsSchema),
  team: z.number(),
  kills: z.number(),
  deaths: z.number(),
  assists: z.number(),
  netWorth: z.number(),
  heroId: z.number(),
  lastHits: z.number(),
  denies: z.number(),
  abilityPoints: z.number(),
  party: z.number(),
  assignedLane: z.number(),
  level: z.number(),
  pings: z.array(PingSchema),
  abilityStats: z.array(z.any()),
  statsTypeStat: z.array(z.number()),
  bookRewards: z.array(z.any()),
  abandonMatchTimeS: z.number(),
});

export type DeadlockPlayer = z.infer<typeof DeadlockPlayerSchema>;

// Match objective schema
export const MatchObjectiveSchema = z.object({
  legacyObjectiveId: z.number(),
  destroyedTimeS: z.number(),
  creepDamage: z.number(),
  creepDamageMitigated: z.number(),
  playerDamage: z.number(),
  playerDamageMitigated: z.number(),
  firstDamageTimeS: z.number(),
  teamObjectiveId: z.number(),
  team: z.number(),
});

export type MatchObjective = z.infer<typeof MatchObjectiveSchema>;

// Source details schema
export const SourceDetailsSchema = z.object({
  statType: z.array(z.number()),
  sourceName: z.array(z.string()),
});

export type SourceDetails = z.infer<typeof SourceDetailsSchema>;

// Damage to players schema
export const DamageToPlayersSchema = z.object({
  targetPlayerSlot: z.number(),
  damage: z.array(z.number()),
});

export type DamageToPlayers = z.infer<typeof DamageToPlayersSchema>;

// Damage source schema
export const DamageSourceSchema = z.object({
  damageToPlayers: z.array(DamageToPlayersSchema),
  sourceDetailsIndex: z.number(),
});

export type DamageSource = z.infer<typeof DamageSourceSchema>;

// Damage dealer schema
export const DamageDealerSchema = z.object({
  dealerPlayerSlot: z.number(),
  damageSources: z.array(DamageSourceSchema),
});

export type DamageDealer = z.infer<typeof DamageDealerSchema>;

// Damage matrix schema
export const DamageMatrixSchema = z.object({
  damageDealers: z.array(DamageDealerSchema),
  sampleTimeS: z.array(z.number()),
  sourceDetails: SourceDetailsSchema.optional(),
});

export type DamageMatrix = z.infer<typeof DamageMatrixSchema>;

// Mid boss schema
export const MidBossSchema = z.object({
  teamKilled: z.number(),
  teamClaimed: z.number(),
  destroyedTimeS: z.number(),
});

export type MidBoss = z.infer<typeof MidBossSchema>;

// Custom user stat definition schema
export const CustomUserStatDefinitionSchema = z.object({
  name: z.string(),
  id: z.number(),
});

export type CustomUserStatDefinition = z.infer<typeof CustomUserStatDefinitionSchema>;

export const PathSchema = z.object({
  playerSlot: z.number(),
  xMin: z.number(),
  yMin: z.number(),
  xMax: z.number(),
  yMax: z.number(),
  xPos: z.number().array(),
  yPos: z.number().array(),
  alive: z.unknown().array(),
  health: z.number().array(),
});
export type Path = z.infer<typeof PathSchema>;

export const MatchPathsSchema = z.object({
  version: z.number(),
  intervalS: z.number(),
  xResolution: z.number(),
  yResolution: z.number(),
  paths: PathSchema.array(),
});
export type MatchPaths = z.infer<typeof MatchPathsSchema>;

export const MatchInfoSchema = z.object({
  durationS: z.number(),
  matchOutcome: z.number(),
  winningTeam: z.number(),
  players: z.array(DeadlockPlayerSchema),
  startTime: z.number().optional(),
  matchId: z.string(),
  legacyObjectivesMask: z.number(),
  gameMode: z.number(),
  matchMode: z.number(),
  objectives: z.array(MatchObjectiveSchema),
  matchPaths: MatchPathsSchema.optional(),
  damageMatrix: DamageMatrixSchema,
  matchPauses: z.array(z.any()),
  customUserStats: z.array(CustomUserStatDefinitionSchema),
  watchedDeathReplays: z.array(z.any()),
  objectivesMaskTeam0: z.string(),
  objectivesMaskTeam1: z.string(),
  midBoss: z.array(MidBossSchema),
  isHighSkillRangeParries: z.boolean().optional(),
  lowPriPool: z.boolean().optional(),
  newPlayerPool: z.boolean().optional(),
  averageBadgeTeam0: z.number().optional(),
  averageBadgeTeam1: z.number().optional(),
});
export type MatchInfo = z.infer<typeof MatchInfoSchema>;

// Match details schema
const MatchDetailsSchema = z.object({
  matchInfo: MatchInfoSchema,
});

export type MatchDetails = z.infer<typeof MatchDetailsSchema>;

// Root schemas
export const MatchDataVersion0Schema = z.object({
  version: z.number(),
  matchId: z.string(),
  matchDetails: MatchDetailsSchema,
});

export type MatchDataVersion0 = z.infer<typeof MatchDataVersion0Schema>;

export const MatchDataVersion1Schema = z.object({
  matchInfo: MatchInfoSchema,
});
export type MatchDataVersion1 = z.infer<typeof MatchDataVersion1Schema>;
