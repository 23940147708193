import { z } from "zod";

export const QuizQuestionSchema = z.object({
  id: z.string(),
  text: z.string(),
  answers: z.array(z.string()),
  correctAnswer: z.string(),
  image: z.string().optional(),
});

export type QuizQuestion = z.infer<typeof QuizQuestionSchema>;

const GameStateSchema = z.object({
  scheduledDate: z.string(),
  userId: z.string(),
  score: z.number(),
  timeLeft: z.number(),
  questionIndex: z.number(),
});

export type GameState = z.input<typeof GameStateSchema>;
