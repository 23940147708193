import { z } from "zod";

const StagesSchema = z.object({
  stageId: z.string(),
  matches: z.array(
    z.object({
      matchId: z.string(),
      teamAId: z.string(),
      teamBId: z.string(),
      winnerTeamId: z.string(),
    }),
  ),
});

export const PredictionSchema = z.object({
  tournamentId: z.string(),
  predictionId: z.string(),
  userId: z.string().nullish(),
  stages: z.array(StagesSchema),
});

export const PredictionRequestSchema = z.object({
  stages: z.array(StagesSchema),
});
export type Prediction = z.infer<typeof PredictionSchema>;
export type PredictionRequest = z.infer<typeof PredictionRequestSchema>;
