import { getConfig } from "@portal-frontend-ssr/config";
import { AxiosError } from "axios";

/**
 * Interceptor function that logs errors during development.
 * @param error - The AxiosError object representing the error.
 * @returns A rejected Promise with the error object.
 */
export const debugLoggingInterceptor = (error: AxiosError) => {
  if (getConfig().ENVIRONMENT === "local") {
    console.error("🚨 AxiosError in API response from", error.config?.url);
  }
  return Promise.reject(error);
};
