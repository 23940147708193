import { z } from "zod";

export const HeroAbilityLevelingPathSchema = z.object({
  heroId: z.string(),
  heroName: z.string(),
  abilityPath: z.array(z.string()),
  abilityPriority: z.array(z.string()),
  rank: z.number(),
  matches: z.number(),
  wins: z.number(),
  winRate: z.number(),
});

export type HeroAbilityLevelingPath = z.infer<typeof HeroAbilityLevelingPathSchema>;
