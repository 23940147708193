import { z } from "zod";

const RocketLeagueLeaderboardTransactionSchema = z.object({
  id: z.string(),
  points: z.number(),
  transactionDate: z.string(),
  tournament: z
    .object({
      id: z.string(),
      circuitId: z.string(),
      name: z.string(),
      teamPlacement: z.string().nullable(),
    })
    .nullable(),
  description: z.string().nullable(),
});
export type RocketLeagueLeaderboardTransaction = z.infer<typeof RocketLeagueLeaderboardTransactionSchema>;

export const RocketLeagueLeaderboardRankingSchema = z.object({
  id: z.string(),
  duplicateIndex: z.number(),
  isOldRoster: z.boolean(),
  gameId: z.string(),
  teamId: z.string(),
  teamName: z.string(),
  category: z.string(),
  subCategory: z.string(),
  points: z.number(),
  calculationDate: z.string(),
  players: z.object({ id: z.string().nullable(), nickname: z.string() }).array(),
  transactions: RocketLeagueLeaderboardTransactionSchema.array(),
  trendNumber: z.number().nullish(),
  rank: z.number(),
});
export type RocketLeagueLeaderboardRanking = z.infer<typeof RocketLeagueLeaderboardRankingSchema>;
