import { z } from "zod";

export const TournamentPrizeSchema = z.object({
  id: z.string(),
  tournamentId: z.string(),
  teamId: z.string().nullable(),
  prize: z.string(),
  subPrize: z.string().array().nullable(),
  positionStart: z.number(),
  positionEnd: z.number(),
});

export type TournamentPrize = z.infer<typeof TournamentPrizeSchema>;
