import { z } from "zod";

export const RecentMatchSchema = z.object({
  startTime: z.string().datetime(),
  matchId: z.string(),
  durationS: z.number(),
  averageMatchBadge: z.number(),
  team0NetWorth: z.number(),
  team1NetWorth: z.number(),
  matchMode: z.number(),
  team0Heroes: z.number().array(),
  team1Heroes: z.number().array(),
});
export type RecentMatch = z.infer<typeof RecentMatchSchema>;
