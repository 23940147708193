import { z } from "zod";

export const GamedataDeadlockHeroStatsSchema = z.object({
  heroId: z.number(),
  heroName: z.string(),
  matches: z.number(),
  winPercentage: z.number(),
  averageKills: z.number(),
  averageDeaths: z.number(),
  averageAssists: z.number(),
  averageSouls: z.number(),
  averagePlayerDamage: z.number(),
  averagePlayerHealing: z.number(),
  averageBossDamage: z.number(),
});
export type GamedataDeadlockHeroStats = z.infer<typeof GamedataDeadlockHeroStatsSchema>;
