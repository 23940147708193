import { z } from "zod";

export const AvgTeamRankDistributionSchema = z.array(
  z.object({
    tier: z.number(),
    subTier: z.number(),
    teamCount: z.number(),
  }),
);
export type AvgTeamRankDistribution = z.infer<typeof AvgTeamRankDistributionSchema>;
