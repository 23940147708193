import { z } from "zod";

export const UserOverallStatsSchema = z.object({
  matches: z.number(),
  winPercentage: z.number(),
  averageSouls: z.number(),
  averageKills: z.number(),
  averageAssists: z.number(),
  averagePlayerDamage: z.number(),
  averageBossDamage: z.number(),
  averagePlayerHealing: z.number(),
});
export type UserOverallStats = z.infer<typeof UserOverallStatsSchema>;
