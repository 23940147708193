import { z } from "zod";

export const MatchStatsSchema = z.object({
  matchId: z.string(),
  startTime: z.string(),
  duration: z.number(),
  matchScore: z.number().nullish(),
  matchMode: z.number(),
  players: z
    .object({
      accountId: z.string(),
      playerSlot: z.number(),
      heroId: z.number(),
      heroName: z.string(),
      team: z.number(),
      win: z.boolean(),
      netWorth: z.number(),
      kills: z.number(),
      deaths: z.number(),
      assists: z.number(),
      playerDamage: z.number(),
      playerHealing: z.number(),
      bossDamage: z.number(),
      ranking: z.number(),
    })
    .array(),
});

export type MatchStats = z.infer<typeof MatchStatsSchema>;
