import { z } from "zod";
import { Team } from "../Team";
import { GameIdSchema } from "../../../shared/schemas/GameId";

const TeamSocialLinksSchema = z.record(
  z.enum(["twitter", "facebook", "instagram", "youtube", "twitch", "discord", "website"]),
  z.object({
    url: z.string().url(),
  }),
);

export const TeamV2Schema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string().optional(),
  nationality: z.string().nullable().optional(),
  gameId: GameIdSchema.optional(),
  socialLinks: TeamSocialLinksSchema.default({}),
  externalId: z.string().nullish(),
});

export type TeamV2 = z.infer<typeof TeamV2Schema>;

export function mapTeamV2SchemaToTeam(team: undefined | null): undefined;
export function mapTeamV2SchemaToTeam(team: TeamV2): Team;
export function mapTeamV2SchemaToTeam(team: TeamV2 | undefined | null): Team | undefined {
  if (!team) {
    return undefined;
  }

  const convertedData: Team = {
    name: team.name,
    uuid: team.id,
    location: team.nationality || undefined,
    shorthand: team.shortName,
    gameId: team.gameId || "cs",
    socialLinks: team.socialLinks,
    externalId: team.externalId || undefined,
  };

  return convertedData;
}

export const TeamTransferHistorySchema = z.object({
  player: z.object({
    id: z.string(),
    nickname: z.string(),
    nationality: z.string().nullable(),
    ingameId: z.string().nullable().optional(),
  }),
  currentTeam: TeamV2Schema.pick({
    id: true,
    name: true,
    shortName: true,
  })
    .extend({
      role: z.string().nullable(),
    })
    .nullable(),
  currentTeamFromDate: z.coerce.date(),
  previousTeamFromDate: z.coerce.date().nullable(),
  previousTeam: TeamV2Schema.pick({
    id: true,
    name: true,
    shortName: true,
  })
    .extend({
      role: z.string().nullable(),
    })
    .nullable(),
});

export type TeamTransferHistory = z.infer<typeof TeamTransferHistorySchema>;

export const TeamAwardSchema = z.object({
  id: z.string(),
  name: z.string(),
  tournament: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  dateAwarded: z.coerce.date(),
  type: z.string(),
});

export type TeamAward = z.infer<typeof TeamAwardSchema>;

// TODO: Remove when all team types have been migrated to V2
export const mapTeamToTeamV2 = (team: Team): TeamV2 => ({
  id: team.uuid,
  name: team.name,
  nationality: team.location ?? null,
  shortName: team.shorthand ?? "",
  gameId: team.gameId ?? "cs",
  socialLinks: team.socialLinks || {},
});
