import { z } from "zod";
import { TeamV2Schema } from "./v2/Team";
import { DotaRoleSchema } from "../../shared/schemas/DotaRole";

export const PlayerSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  nickname: z.string(),
  nationality: z.string().nullable(),
  dateOfBirth: z.coerce.date().nullable(),
  role: z.string().nullish(),
  ingameId: z.string().nullish(),
  team: TeamV2Schema.nullish(),
  dotaMetadata: z
    .object({
      role: DotaRoleSchema.nullish().catch(null),
      tiAppearances: z.number().nullish(),
    })
    .nullish(),
  csMetadata: z
    .object({
      role: z.enum(["awper", "rifler"]).nullish(),
      majorAppearances: z.number().nullish(),
    })
    .nullish()
    .catch(null),
  isRetired: z.boolean().optional().default(false),
});
export type Player = z.infer<typeof PlayerSchema>;
