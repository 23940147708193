import { z } from "zod";
import { TeamV2Schema } from "./v2/Team";

export const TournamentTeamMetadataSchema = z
  .object({
    bountyValue: z.number().nullish(),
    seed: z.number().nullish(),
  })
  .nullish();

export const TournamentTeamSlotSchema = z.object({
  team: TeamV2Schema.nullable(),
  index: z.number(),
  qualificationMethod: z.string().nullish(),
  metadata: TournamentTeamMetadataSchema.catch(null),
});

export type TournamentTeamSlot = z.infer<typeof TournamentTeamSlotSchema>;
