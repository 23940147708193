import { z } from "zod";

export const TopPlayerSchema = z.object({
  accountId: z.coerce.number(),
  totalGames: z.coerce.number(),
  totalWins: z.coerce.number(),
  top3HeroesWithCounts: z.array(
    z.object({
      heroId: z.number(),
      gamesPlayed: z.number(),
    }),
  ),
});

export type TopPlayer = z.infer<typeof TopPlayerSchema>;
