import { z } from "zod";

export const AbilityAttributesSchema = z.object({
  key: z.string(),
  value: z.union([z.string().array(), z.string()]),
  header: z.string(),
});

export const AbilitySchema = z.object({
  dname: z.string().optional(),
  behavior: z.union([z.string().array(), z.string()]).optional(),
  dmg_type: z.union([z.string().array(), z.string()]).optional(),
  bkbpierce: z.union([z.string().array(), z.string()]).optional(),
  dispellable: z.string().optional(),
  target_team: z.union([z.string().array(), z.string()]).optional(),
  target_type: z.union([z.string().array(), z.string()]).optional(),
  desc: z.string().optional(),
  attrib: AbilityAttributesSchema.array().optional(),
  isGrantedByShard: z.boolean().optional(),
  isGrantedByScepter: z.boolean().optional(),
  isShardUpgrade: z.boolean().optional(),
  isScepterUpgrade: z.boolean().optional(),
  id: z.coerce.number(),
  position: z.coerce.number().default(0),
});

export type Ability = z.infer<typeof AbilitySchema>;

export const TalentSchema = z.object({
  name: z.string(),
  level: z.number(),
  dName: z.string(),
  id: z.number(),
});

export type DotaTalent = z.infer<typeof TalentSchema>;

export const FacetSchema = z.object({
  id: z.number(),
  name: z.string(),
  deprecated: z.coerce.boolean().optional(),
  icon: z.string(),
  color: z.string(),
  gradient_id: z.number(),
  title: z.string(),
  description: z.string(),
});

export type DotaFacet = z.infer<typeof FacetSchema>;

const DotaHeroSchema = z.object({
  id: z.number(),
  name: z.string(),
  localizedName: z.string(),
  primaryAttr: z.string(),
  attackType: z.string(),
  roles: z.array(z.string()),
  baseHealth: z.number().nullable(),
  baseHealthRegen: z.number().nullable(),
  baseMana: z.number().nullable(),
  baseManaRegen: z.number().nullable(),
  baseArmor: z.number().nullable(),
  baseMr: z.number().nullable(),
  baseAttackMin: z.number().nullable(),
  baseAttackMax: z.number().nullable(),
  baseStr: z.number().nullable(),
  baseAgi: z.number().nullable(),
  baseInt: z.number().nullable(),
  strGain: z.number().nullable(),
  agiGain: z.number().nullable(),
  intGain: z.number().nullable(),
  attackRange: z.number().nullable(),
  projectileSpeed: z.number().nullable(),
  attackRate: z.number().nullable(),
  baseAttackTime: z.number().nullable(),
  attackPoint: z.number().nullable(),
  moveSpeed: z.number().nullable(),
  turnRate: z.number().nullable(),
  cmEnabled: z.boolean().nullable(),
  legs: z.number().nullable(),
  dayVision: z.number().nullable(),
  nightVision: z.number().nullable(),
});

export type DotaHero = z.infer<typeof DotaHeroSchema>;

export const DotaHeroDetailSchema = DotaHeroSchema.extend({
  abilities: z.record(AbilitySchema),
  innateAbility: AbilitySchema,
  itemAbilities: z.record(AbilitySchema),
  talents: TalentSchema.array(),
  facets: FacetSchema.array(),
});

export type DotaHeroDetail = z.infer<typeof DotaHeroDetailSchema>;
