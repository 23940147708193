import { z } from "zod";
import { blastApi } from "../../blastApi";

const UserWalletSchema = z.object({
  amount: z.number().int(),
});

export const getUserWallet = async (userId: string) => {
  const { data } = await blastApi.get<unknown>(`/v1/points/wallets/${userId}`);

  return UserWalletSchema.parse(data);
};
