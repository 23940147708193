import { z } from "zod";

const MatchBaseSchema = z.object({
  startTime: z.string().datetime(),
  matchId: z.string(),
  durationS: z.number(),
  averageMatchBadge: z.number(),
  team0NetWorth: z.number(),
  team1NetWorth: z.number(),
});

export const HighestRankedMatchSchema = MatchBaseSchema;
export type HighestRankedMatch = z.infer<typeof HighestRankedMatchSchema>;

export const LongestMatchSchema = MatchBaseSchema;
export type LongestMatch = z.infer<typeof LongestMatchSchema>;

export const MostKillsMatchSchema = MatchBaseSchema.extend({ totalKills: z.number() });
export type MostKillsMatch = z.infer<typeof MostKillsMatchSchema>;

export const ClosestMatchSchema = MatchBaseSchema;
export type ClosestMatch = z.infer<typeof ClosestMatchSchema>;
