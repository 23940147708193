import { z } from "zod";

export const ValveLeaderboardEntrySchema = z.object({
  accountName: z.string().optional(),
  rank: z.number().optional(),
});

export type ValveLeaderboardEntry = z.infer<typeof ValveLeaderboardEntrySchema>;

export const ValveLeaderboardSchema = ValveLeaderboardEntrySchema.array();

export type ValveLeaderboard = z.infer<typeof ValveLeaderboardSchema>;

export const ValveLeaderBoardListSchema = z.object({
  region: z.number(),
  heroId: z.number(),
  data: ValveLeaderboardSchema,
});

export type ValveLeaderBoardList = z.infer<typeof ValveLeaderBoardListSchema>;
