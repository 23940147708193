import { z } from "zod";
import { CircuitSchema } from "./Circuit";
import { BaseTournamentSchema } from "./Tournament";
import { StageSchema } from "./Stage";
import { TeamV2Schema } from "./v2/Team";
import { MapV2Schema } from "./v2/Map";

export const DotaMatchMetadataSchema = z.object({
  _t: z.enum(["dota_match", "cs_match", "rl_match"]),
  externalStreamUrl: z.string().nullish(),
});

export const BountyMatchMetadataSchema = z.object({
  _t: z.literal("bounty_match"),
  wager: z.number().nullish(),
});

export const MatchMetadataSchema = z.discriminatedUnion("_t", [DotaMatchMetadataSchema, BountyMatchMetadataSchema]);
export type MatchMetadata = z.infer<typeof MatchMetadataSchema>;

export const DetailedMatchSchema = z.object({
  id: z.string(),
  name: z.string(),
  scheduledAt: z.string().datetime(),
  type: z.string(),
  index: z.number(),
  externalId: z.string().nullable(),
  circuit: CircuitSchema,
  tournament: BaseTournamentSchema.pick({
    id: true,
    name: true,
    startDate: true,
    endDate: true,
    prizePool: true,
    externalId: true,
  }),
  stage: StageSchema,
  teamA: TeamV2Schema.pick({ id: true, name: true, shortName: true, nationality: true, externalId: true }),
  teamB: TeamV2Schema.pick({ id: true, name: true, shortName: true, nationality: true, externalId: true }),
  teamAScore: z.number(),
  teamBScore: z.number(),
  maps: MapV2Schema.array(),
  metadata: MatchMetadataSchema.nullable(),
});
export type DetailedMatch = z.infer<typeof DetailedMatchSchema>;
