import { z } from "zod";
import { TournamentSchema } from "./Tournament";
import { TeamSchema } from "./Team";
import { MapSchema } from "./Map";
import { MatchMetadataSchema } from "./Match";

const NextSeriesSchema = z.object({
  tournamentUUID: z.string(),
  seriesUUID: z.string(),
  bracketPosition: z.enum(["POSITION_A", "POSITION_B"]),
});

export const SeriesSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  timeOfSeries: z.string(),
  type: z.string(),
  index: z.number(),
  tournament: TournamentSchema,
  metadata: MatchMetadataSchema.nullish(),
  teamA: TeamSchema.nullish().transform((team) => team ?? null),
  teamB: TeamSchema.nullish().transform((team) => team ?? null),
  teamAScore: z.number(),
  teamBScore: z.number(),
  maps: z.array(MapSchema).nullable().optional(),
});

export const SeriesByTournamentSchema = SeriesSchema.extend({
  winnerGoesTo: NextSeriesSchema.nullable().optional(),
  loserGoesTo: NextSeriesSchema.nullable().optional(),
});

export type Series = z.infer<typeof SeriesSchema>;
