import { z } from "zod";

export const NeutralItemSchema = z.object({
  itemId: z.number(),
  matches: z.number(),
  winrate: z.number(),
});

export const NeutralItemsSchema = z.object({
  tier1: z.array(NeutralItemSchema),
  tier2: z.array(NeutralItemSchema),
  tier3: z.array(NeutralItemSchema),
  tier4: z.array(NeutralItemSchema),
  tier5: z.array(NeutralItemSchema),
});

export const HighRankedHeroNeutralItemsSchema = z.object({
  heroId: z.string(),
  position: z.string().nullish(),
  facet: z.string().nullish(),
  neutralItems: NeutralItemsSchema.nullable(),
});

export type NeutralItem = z.infer<typeof NeutralItemSchema>;
export type HighRankedHeroNeutralItems = z.infer<typeof HighRankedHeroNeutralItemsSchema>;
