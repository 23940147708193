import { z } from "zod";
import { HeroTierSchema } from "./HeroTier";

export const HeroStatsSchema = z.object({
  heroId: z.number(),
  heroName: z.string(),
  matchesPlayed: z.number(),
  avgNetWorth: z.number(),
  avgKills: z.number(),
  avgAssists: z.number(),
  avgNeutralDamage: z.number(),
  avgPlayerDamage: z.number(),
  avgPlayerHealing: z.number(),
  tier: HeroTierSchema.nullish().default("S"),
});

export type HeroStats = z.infer<typeof HeroStatsSchema>;
