import axios from "axios";
import { z } from "zod";
import { getConfig } from "@portal-frontend-ssr/config";

const FlagSchema = z.object({
  feature: z.object({
    name: z.string(),
  }),
  enabled: z.boolean(),
});
const FlagResponseSchema = z.array(FlagSchema);

const flagsmithApi = axios.create({
  baseURL: "https://fs.blast.tv/api/v1",
  headers: {
    "X-Environment-Key": getConfig().FLAGSMITH_ENVIRONMENT_ID,
  },
});

/**
 * Create a user identity with traits in Flagsmith and return the flags for that user
 * https://docs.flagsmith.com/clients/rest#send-identity-with-traits-and-receive-flags
 * (Same behaviour as Flagsmiths JavaScript SDK https://github.com/Flagsmith/flagsmith-js-client/blob/main/flagsmith-core.ts#L232)
 */
async function postIdentityAndTraits({
  identifier,
  traits,
}: {
  identifier: string;
  traits: {
    trait_key: string;
    trait_value: string;
  }[];
}) {
  const response = await flagsmithApi.post(`/identities/`, {
    identifier,
    traits,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return response.data.flags;
}

export async function getAnonymousFlags() {
  const response = await flagsmithApi.get("/flags/");
  const flags = FlagResponseSchema.parse(response.data);
  return flags;
}

export async function getFlags<K extends string>({
  userId,
  email,
}: {
  userId?: string;
  email?: string;
}): Promise<Record<K, boolean>> {
  const flagData: unknown =
    userId && email
      ? await postIdentityAndTraits({
          identifier: userId,
          traits: [
            {
              trait_key: "email",
              trait_value: email,
            },
          ],
        })
      : await getAnonymousFlags();

  const flags = FlagResponseSchema.parse(flagData);
  const mapped = flags.reduce(
    (acc, flag) => ({
      ...acc,
      [flag.feature.name]: flag.enabled,
    }),
    {} as Record<K, boolean>,
  );
  return mapped;
}
