import { z } from "zod";

export const BaseStatsSchema = z.object({
  matches: z.number(),
  matchWins: z.number(),
  matchLosses: z.number(),
  matchesWinPercentage: z.number(),
  series: z.number(),
  seriesWins: z.number(),
  seriesLosses: z.number(),
  seriesWinPercentage: z.number(),
  goalsForSum: z.number(),
  goalsForAvg: z.number(),
  goalsAgainstSum: z.number(),
  goalsAgainstAvg: z.number(),
  shootingPercentage: z.number(),
  shotsForSum: z.number(),
  shotsForAvg: z.number(),
  shotsForPercentage: z.number(),
  shotsAgainstSum: z.number(),
  shotsAgainstAvg: z.number(),
  shotsAgainstPercentage: z.number(),
  savesSum: z.number(),
  savesAvg: z.number(),
  assistsSum: z.number(),
  assistsAvg: z.number(),
  scoreSum: z.number(),
  scoreAvg: z.number(),
  demoInflictedSum: z.number(),
  demoInflictedAvg: z.number(),
  demoTakenSum: z.number(),
  demoTakenAvg: z.number(),
  overtimeMatches: z.number(),
  longestOvertime: z.number().nullish().catch(null),
});

export type BaseStats = z.input<typeof BaseStatsSchema>;
