import axios from "axios";
import { getConfig } from "@portal-frontend-ssr/config";
import { authInterceptor } from "./interceptors/authInterceptor";
import { debugLoggingInterceptor } from "./interceptors/debugLoggingInterceptor";

const blastApi = axios.create({
  baseURL: getConfig().API_BASE_URL,
});

blastApi.interceptors.request.use(authInterceptor);
blastApi.interceptors.response.use((response) => response, debugLoggingInterceptor);

export { blastApi };
