import { z } from "zod";

export const HighRankedHeroAbilityUpgradesSchema = z.object({
  heroId: z.string(),
  position: z.string().nullish(),
  facet: z.string().nullish(),
  abilityUpgrades: z
    .object({
      index: z.number(),
      abilityId: z.number(),
    })
    .array(),
});

export type HighRankedHeroAbilityUpgrades = z.infer<typeof HighRankedHeroAbilityUpgradesSchema>;
