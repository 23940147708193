import cookies from "js-cookie";
import { getDomain } from "./getDomain";

export const clearSessionCookies = () => {
  const domain = getDomain();
  cookies.remove("access_token", {
    domain: `.${domain}`,
  });
  cookies.remove("refresh_token", {
    domain: `.${domain}`,
  });
  cookies.remove("id_token", {
    domain: `.${domain}`,
  });
};
