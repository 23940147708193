import { z } from "zod";

export const HeroItemBuildSchema = z.object({
  heroId: z.coerce.string(),
  heroName: z.string(),
  tier: z.enum(["early", "mid", "late"]),
  rank: z.number(),
  itemIds: z.array(z.string()),
  matches: z.number(),
  wins: z.number(),
  winRate: z.number(),
});

export type HeroItemBuild = z.infer<typeof HeroItemBuildSchema>;
