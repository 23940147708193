import { z } from "zod";
import { GameIdSchema } from "../shared/schemas/GameId";

export const GenderSchema = z.enum(["MALE", "FEMALE", "NON_BINARY", "PREFER_NOT_TO_SAY"]);

export const UserProfileSchema = z.object({
  id: z.string(),
  username: z.string(),
  email: z.string().email(),
  gender: GenderSchema,
  country: z.string(),
  avatarId: z.string(),
  optIn: z.boolean(),
  chatRole: z.enum(["admin", "moderator", "verified"]).optional(),
  favoriteTeams: z.string().array().optional(),
  avatars: z.string().array().optional(),
  emojis: z.string().array().optional(),
  steamId: z.string().nullish(),
  steamUsername: z.string().nullish(),
  highContrastMode: z.boolean().optional(),
  chatRulesAcceptedAt: z.string().datetime().optional(),
  vipStatusActiveUntil: z.string().datetime().optional(),
  preferredGames: z.array(GameIdSchema).optional(),
});

export type UserProfile = z.infer<typeof UserProfileSchema>;
